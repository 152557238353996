<template>
	<div :style="Bg">
		<Header :title="$t('google.backupVerifyTitle')"></Header>
		
		<div class="column-center-content main-content" style="margin-top: 80px;">
			<label style="padding: 10px 20px; color: #999999; font-size: 14px; width: 100%;">{{$t('google.backupVerifyTips')}}</label>
			
			<div style="padding: 0px 20px 10px 20px; width:100%;">
				<div style="background-color: #F2F2F2; height: 40px; width: 100%; padding: 0px 10px; display: flex; justify-content: space-between; border-radius: 5px;">
					
					<label style="line-height: 40px;">A67DJSG8KRF9GSND</label>
					<label style="line-height: 40px; color: #009245; font-weight: bold;">{{$t('google.stickup')}}</label>
				</div>
			</div>
			
			<div class="btn-content" style="margin-bottom: 0px; margin-top: 60px;">
				<v-btn rounded block @click="goNext" style="background-color: #009245; color: white; height: 40px;">
					下一步
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../components/NavHeader.vue'
	// import { Toast, Indicator, MessageBox } from 'mint-ui';
	
	export default{
		name: "BackupVerification",
		components: {
			Header
		},
		data:() => ({
			Bg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
		}),
		methods: {
			goNext() {
				
			}
		}
	}
</script>

<style>
</style>
